import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // { provide: APP_ID, useValue: 'serverApp' },
    FontAwesomeModule,
    RouterModule.forRoot([{ path: '', component: LandingPageComponent }]),
  ],
  exports: [AppComponent, FontAwesomeModule],
  bootstrap: [AppComponent],
})
export class AppModule {}

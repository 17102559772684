import { Component } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faLaptop } from '@fortawesome/pro-solid-svg-icons/faLaptop';
import { faMobile } from '@fortawesome/pro-solid-svg-icons/faMobile';
import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  icon = {
    laptop: faLaptop,
    edit: faEdit,
    mobile: faMobile,
    truck: faTruck,
    linkedIn: faLinkedinIn,
    twitter: faTwitter,
    facebook: faFacebookF,
    youtube: faYoutube,
    circle: faCircle,
  };
}
